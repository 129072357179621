/**
 * 昇順ソート
 */
function ascOrder(a, b) {
  if (a.date > b.date) {
    return 1;
  } else {
    return -1;
  }
}

export default ascOrder;